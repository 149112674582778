<template>
  <div>
    <b-row class="mt-2">
      <b-col xl="6" lg="6" md="12" sm="12" cols="12"  order="last">
        <b-card-title class="text-center">My Profile</b-card-title>
        <b-card>
          <b-card-header> 
            <div class="demo-inline-spacing">
                <b-avatar
                size="lg"
                :src="avatarIMG"/>
                <div>
                     <b-card-text class="mb-0">Discord ID</b-card-text>
                   <b-card-title class="font-weight-bolder">{{user.username}}</b-card-title>
                   </div>
            </div>           
            <div>
                 <b-card-text>Quest Completed: <span class="text-white font-weight-bolder">{{questAmount}}</span></b-card-text>
            </div>
          </b-card-header>
          <b-card-body>
            <b-form @submit.prevent="saveProfile">
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Wallet Invitation Address" 
                  v-b-tooltip.hover.top="'Address where you hold your Elite Invitations (If you don\'t hold any put your wallet on both fields)'" label-for="v-first-name">
                    <b-form-input 
                    id="v-first-name" 
                    v-model="profile.walletinvitation"
                    required
                    placeholder="395eKYV9oKn6ZqjvCD5uUYHUUBFw2guYsEwSztDx4bFR" />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Wallet address you want whitelisted"
                    label-for="v-email">
                    <b-form-input
                    v-model="profile.wallettoreceive"
                      id="v-email"
                      required
                      placeholder="395eKYV9oKn6ZqjvCD5uUYHUUBFw2guYsEwSztDx4bFR"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Twitter Handle" label-for="v-mobile">
                    <b-form-input
                    v-model="profile.twitteruser"
                      id="v-mobile"
                      required
                      placeholder="@"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" class="mt-1">
                  <b-button block pill type="submit" class="backgroundBtn mr-1">
                    Save
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col xl="6" lg="6" md="12" sm="12" cols="12"  order-lg="last" order-xl="last">
        <b-card-title class="text-center">Notification Settings</b-card-title>
        <b-card>
            <b-card-header>
                <b-card-text>Get alerts for new store items or completed quests</b-card-text>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col cols="6">
                        <b-card-text class="font-weight-bolder text-white text-left">Store Items</b-card-text>
                    </b-col>
                     <b-col cols="6">
                        <div class="text-right">
                           <b-form-checkbox
                             v-model="profile.notifications.storeitems"
                            name="check-button"
                            switch
                            inline
                            />
                            </div>
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                     <b-col cols="6">
                        <b-card-text class="font-weight-bolder text-white text-left">Completed Quest</b-card-text>
                    </b-col>
                     <b-col cols="6">
                        <div class="text-right">
                           <b-form-checkbox
                            v-model="profile.notifications.completedquest"
                            name="check-button"
                            switch
                            inline
                            />
                            </div>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BAvatar,
  BCardText,
  BFormCheckbox,
  VBTooltip
} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'
import SellerService from '@/services/SellerService';
const user = JSON.parse(localStorage.getItem("auth"));
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BAvatar,
    BCardText,
    BFormCheckbox
  },
    directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data(){
    return{
        user:user,
        questAmount:0,
        profile:{
            walletinvitation:null,
            wallettoreceive:null,
            twitteruser:null,
            notifications:{
                storeitems:true,
                completedquest:true
            }
        }
    }
  },
  computed:{
       avatarIMG:function(){
      if (this.user.avatar!=null) {
         if (this.user.avatar.substring(0,2) === 'a_') {
          return  "https://cdn.discordapp.com/avatars/"+ this.user.discordId+"/"+this.user.avatar+".gif"  
          }

          return  "https://cdn.discordapp.com/avatars/"+ this.user.discordId+"/"+this.user.avatar+".png"  
      }
    },
  },
  mounted:function(){
    if(this.user){
      this.bringQuestCompleted();
      this.getProfiloUser();

    }
  },
  methods:{
    bringQuestCompleted:async function(){
      const a=(await SellerService.questCompleted(this.user.discordId)).data;
      if (a["challengeCompleted"]){
        this.questAmount=a["challengeCompleted"]
      }
    },
    getProfiloUser:async function(){
      const ex=(await SellerService.getProfile(this.user.discordId)).data
      if(ex){
        this.profile=ex;
      }
    },
    saveProfile:async function(){
      await SellerService.saveProfile(this.user.discordId,this.profile)
      this.$swal({
          title: "",
          text: "Profile Saved Successfull",
          icon: "success",
           allowOutsideClick: false,
          showClass: {
            popup: "animate__animated animate__fadeIn",
          },
          customClass: {
            confirmButton: "btn btn-success",
          },
          buttonsStyling: false,
        }).then(result => {
        if (result.value) {
                this.$router.go(0)
         }
      });
    }
  }
};
</script>
<style lang="scss">
.backgroundBtn {
  background: linear-gradient(
    87.2deg,
    rgba(164, 136, 255, 0.8) 2.33%,
    rgba(74, 44, 174, 0.83) 51.02%,
    #27088e 94.9%
  );
  border: transparent;
  padding: 18px 80px;
}
// .backgroundCard{
//   background: linear-gradient(354.06deg, #000000 4.71%, rgba(31, 46, 109, 0) 95.63%)!important;
// }
</style>